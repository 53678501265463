(function() {
	/** Newsletter Overlay JS **/
	jQuery(document).ready(function(){
		
		jQuery('#newsletter-signup').click(function(){
			// The main toggle action
			jQuery('.newsletter-overlay-interior').toggle();
			jQuery('#newsletter-signup-li').toggleClass('newsletter-on');
		});
		
		// Handle input clicking and blur
		jQuery('#newsletter-overlay-input').click(function(){
			var val = jQuery(this).val();
			if(val == 'EMAIL ADDRESS'){
				val = '';
		    }
			jQuery('#newsletter-overlay-input').val(val)
		});
		jQuery('#newsletter-overlay-input').blur(function(){
			var val = jQuery(this).val();
			if(val == '') {
				jQuery('#newsletter-overlay-input').val('EMAIL ADDRESS');				
			}
		});
		
	});
	
})();