(function() {
	
	// Add validation hints
	Validation.defaultOptions.immediate = true;
	Validation.defaultOptions.addClassNameToContainer = true;
	
	Event.observe(document, 'dom:loaded', function() {
	    var inputs = $$('ul.options-list input');
	    for (var i = 0, l = inputs.length; i < l; i ++) {
	        inputs[i].addClassName('change-container-classname');
	    }
	})
	
	if (!window.Andalou) {
	    window.Andalou = {};
	}
	Andalou.templatesPattern =  /(^|.|\r|\n)(\{\{(.*?)\}\})/;
	
	Andalou.TopCart = {
	    initialize: function(container) {
	        this.container = $(container);
	        this.element = this.container.up(0);
	        this.elementHeader = this.container.previous(0);
	        this.intervalDuration = 4000;
	        this.interval = null;
	        this.onElementMouseOut = this.handleMouseOut.bindAsEventListener(this);
	        this.onElementMouseOver = this.handleMouseOver.bindAsEventListener(this);
	        this.onElementMouseClick = this.handleMouseClick.bindAsEventListener(this);
	        this.element.observe('mouseout', this.onElementMouseOut);
	        this.element.observe('mouseover', this.onElementMouseOver);
	        this.elementHeader.observe('click', this.onElementMouseClick);
	    },
	
	    handleMouseOut: function (evt) {
	        if ($(this.elementHeader).hasClassName('expanded')) {
	            this.interval = setTimeout(this.hideCart.bind(this), this.intervalDuration);
	        }
	    },
	
	    handleMouseOver: function (evt) {
	        if (this.interval !== null) {
	             clearTimeout(this.interval);
	             this.interval = null;
	        }
	    },
	
	    handleMouseClick: function (evt) {
	        if (!$(this.elementHeader).hasClassName('expanded') && !$(this.container.id).hasClassName('process'))  {
	            this.showCart();
	        }
	        else {
	            this.hideCart();
	        }
	    },
	
	    showCart: function (timePeriod) {
	        this.container.parentNode.style.zIndex=992;
	        new Effect.SlideDown(this.container.id, { duration: 0.01,
	            beforeStart: function(effect) {$( effect.element.id ).addClassName('process');},
	            afterFinish: function(effect) {$( effect.element.id ).removeClassName('process'); }
	            });
	        $(this.elementHeader).addClassName('expanded');
	        if(timePeriod) {
	            this.timePeriod = timePeriod*1000;
	            this.interval = setTimeout(this.hideCart.bind(this), this.timePeriod);
	        }
	    },
	
	    hideCart: function () {
	    	if (!$(this.container.id).hasClassName('process')) {
	            new Effect.SlideUp(this.container.id, { duration: 0.01,
	                beforeStart: function(effect) {$( effect.element.id ).addClassName('process');},
	                afterFinish: function(effect) {
	                    $( effect.element.id ).removeClassName('process');
	                    effect.element.parentNode.style.zIndex=1;
	                    }
	                });
	        }
	        if (this.interval !== null) {
	            clearTimeout(this.interval);
	            this.interval = null;
	        }
	        $(this.elementHeader).removeClassName('expanded');
	    }
	};

})();