;(function( $ ){
	
	$(window).load(function () {
		$(document).ready(function(){
			collage();
			collage_mobile();
			
		    $(".Image_Wrapper img").hover(
		         function(){this.src = this.src.replace("_hover_off","_hover_on");},
		         function(){this.src = this.src.replace("_hover_on","_hover_off");
		    });
		    
		    // jquery.collageCaption.js is not used currently, but could be with this call:
			//$('.Collage').collageCaption();
		    
		});
	});
	
	function collage() {
		$('.Collage').removeWhitespace().collagePlus(
				{
            	  'targetHeight' : 185,  // was 185 originally before adding phone(mobile) grid
            	  'fadeSpeed' : 2000,
            	  'direction' : 'horizontal',
            	  'effect' : "effect-2",
            	  'allowPartialLastRow' : false
				}
			);
	};
	
	function collage_mobile () {
		$('.mobile .Collage').removeWhitespace().collagePlus(
				{
            	  'targetHeight' : 100,  // was 185 originally before adding phone(mobile) grid
            	  'fadeSpeed' : 2000,
            	  'direction' : 'horizontal',
            	  'effect' : "effect-2",
            	  'allowPartialLastRow' : false
				}
			);
	};
	
    var resizeTimer = null;
    var resizeTimerMobile = null;
    
    $(window).bind('resize', function() {
        // hide all the images until we resize them
        $('.Collage .Image_Wrapper').css("opacity", 0);
        $('.mobile .Collage .Image_Wrapper').css("opacity", 0);
        // set a timer to re-apply the plugin
        
        if (resizeTimer) clearTimeout(resizeTimer);
        resizeTimer = setTimeout(collage, 200);
        
        if (resizeTimerMobile) clearTimeout(resizeTimerMobile);
        resizeTimerMobile = setTimeout(collage_mobile, 200);
    });
	
    
})( jQuery );